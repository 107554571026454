<template>
  <div class="container pd col-start-center">
    <headBar
      :title="$store.state.flagJson.type ? '虎鹿瘦喜 新年FLAG' : '超鹿健康资料'"
      left-arrow
      @click-left="newAppBack"
    />
    <img class="banner" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/banner.png" alt="">
    <!-- 一个选择块 -->
    <div class="check-content">
      <h2 class="title f36">以下哪些部位有过伤病或疼痛？</h2>
      <p class="f22 mt-8 grey">(可多选)</p>
      <ul class="check-ul row-start-center van-row--flex">
        <li :class="['check-item--more', 'row-2', 'row-start-center', checkedList.includes(item.code) ? 'checked' : '']" v-for="item in list" :key="item.code" @click="choose(item.code, 100)">
          <img class="check-icon" :src="item.img" alt="">
          <div>
            <p class="f28 dark fw6">{{item.title}}</p>
          </div>
        </li>
        <li :class="['check-item--more', 'row-2', 'row-center-center', checkedList.length === 0 ? 'checked' : '']" key="a" @click="choose('0', 100)">
          <div>
            <p class="f28 dark fw6">暂无疼痛</p>
          </div>
        </li>
      </ul>
    </div>
    <!-- 一个选择块 -->
    <div class="check-content">
      <h2 class="title f36">你的运动频率是什么样的？</h2>
      <ul class="check-ul row-start-center van-row--flex">
        <li :class="['check-item--single', 'row-2', 'row-start-center', checkedList2.includes(item.code) ? 'checked' : '']" v-for="item in list2" :key="item.code" @click="choose(item.code)">
          <div>
            <p class="f28 dark fw6">{{item.title}}</p>
          </div>
        </li>
      </ul>
    </div>

    <div class="button-box fixed">
      <div class="cl-button f32 fw5 row-center-center" @click="goNext">下一步（ 6/7 ）</div>
    </div>
  </div>
</template>

<script>
import { newAppBack } from '@/lib/appMethod'
import { getList } from './index'
import headBar from '@/components/app/headBar'
import userMixin from '@/mixin/userMixin'
import { getParam } from '@/lib/utils'
export default {
  mixins: [userMixin],
  components: {
    headBar,
  },
  data() {
    return {
      list: [],
      checkedList: [],

      list2: [],
      checkedList2: [],
    }
  },
  computed: {},
  methods: {
    newAppBack,
    choose(code, max) {
      if (max) {
        // 多选 最多max项
        if (code === '0') {
          this.checkedList = []
          return
        }
        let index = this.checkedList.indexOf(code)
        if (index > -1) {
          this.checkedList.splice(index, 1)
        } else {
          this.checkedList.push(code)
          if (this.checkedList.length > max) {
            this.checkedList.shift()
          }
        }
      } else {
        // 单选
        this.checkedList2 = []
        this.checkedList2.push(code)
      }

    },
    goNext() {
      if (this.checkedList2.length === 0) {
        this.$toast({
          message: '请选择',
          duration: 3000
        });
        return
      }
      this.$store.commit('setFlagJson', {painSite: this.checkedList, newSportFrequency: this.checkedList2})
      this.$router.push('page-end')
      this.countPoint('43','311','1483')
    }
  },
  async created() {
    await this.$getAllInfo()
    let obj = await getList(this.userId, this.token, 'painSite')
    let obj2 = await getList(this.userId, this.token, 'newSportFrequency')

    this.checkedList = obj.codes
    this.list = obj.list

    this.checkedList2 = obj2.codes
    this.list2 = obj2.list
    this.countPoint('43','311','1482')
  },
}
</script>
<style lang="less" scoped>
@import url(./index.less);
.container {
  background-color: #F9FAFB;
}
</style>
